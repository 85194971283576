import React from 'react';
import {
  ContainerDimensions,
  TextInput,
  TreeTableItem,
  TreeViewTable,
  TreeViewTableColumn,
  SelectInput,
  SelectInputOption,
  Notification,
  Spinner,
  SpinnerSize,
  Icon,
  icons,
  TreeViewItemToggleDiv,
  NumberInput,
} from '@danfoss/etui-core/';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { EmptyState, useModal } from '@danfoss/etui-sm';
import { useTheme } from '@danfoss/etui-system';
import { useRefrigLayout } from '../../context';
import {
  DeviceCompressorData,
  DiscoveryTableData,
} from '../../types/DiscoveryPage.types';
import {
  generateNumberArray,
  getCurrentSuctionGroupCount,
  getSuctionGroupCount,
} from '../../utils';
import { LayoutDropDownMenu } from '../../../LayoutDropDownMenu';
import {
  getIsAddressEditable,
  getIsDualSuctionEditable,
  getIsModelEditable,
  getIsNameEditable,
  getIsSuctionGroupEditable,
  hasSpecialCharacters,
  processCompressorData,
  updateSelectedDeviceInfo,
  validateAddress,
} from '../../actions';
import { SuctionGroupWaringModal } from '../../modals';
import { checkforSameDevices } from '../../utils/check-same-devices';
import { CompressorsFlowAddPack } from './CompressorsFlowAddPack';

export interface CompressorsFlowTableProps {
  handleOnCopyOk: (copyAmount: number, selectedRowIndex: number) => void;
  handleDeleteSelectedRow: (selectedRowIndex: number) => void;
  handleOnSuctionChange: (
    selectedRowIndex: number,
    selectedSuctionGroupValue: number,
  ) => void;
  tableDataLoading: boolean;
  handleOnAddRack: () => void;
}

export const CompressorsFlowTable = ({
  handleOnCopyOk,
  handleDeleteSelectedRow,
  handleOnSuctionChange,
  tableDataLoading,
  handleOnAddRack,
}: CompressorsFlowTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    deviceTypeModelList,
    deviceCompressorData,
    deviceConstraintsData,
    discoveryTableData,
    setDiscoveryTableData,
    setDeviceCompressorData,
  } = useRefrigLayout();
  const [compressorTableData, setCompressorTableData] = React.useState<
    TreeTableItem[]
  >([]);

  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(0);
  const [selectedSgCount, setSelectedSgCount] = React.useState<number>(1);
  const [warningMessage, setWarningMessage] = React.useState<string>('');
  const [deviceNames, setDeviceNames] = React.useState<string[]>([]);
  const [deviceAddress, setDeviceAddress] = React.useState<string[]>([]);
  const [deviceSuctionNames, setDeviceSuctionNames] = React.useState<string[]>(
    [],
  );

  let currentSgFocus: React.FocusEvent<HTMLElement> = null;
  const [isTableTextValuesUpdated, setIsTableTextValuesUpdated] =
    React.useState<boolean>(false);

  const handleOnSgLessCountOkClick = () => {
    handleOnSuctionChange(selectedRowIndex, selectedSgCount);
  };
  const [showWarningModal] = useModal(SuctionGroupWaringModal, {
    handleOnSgLessCountOkClick,
    warningMessage,
  });

  const handleShowWarningModal = () => {
    showWarningModal();
  };

  const maxSgReached =
    getSuctionGroupCount(deviceCompressorData?.suction) >=
      +deviceConstraintsData?.max_sg ?? false;

  React.useEffect(() => {
    if (isTableTextValuesUpdated) {
      getCompressorTableData();
    }
  }, [isTableTextValuesUpdated]);

  React.useEffect(() => {
    if (deviceCompressorData?.suction?.length) {
      processTableTextValues();
    }
  }, [deviceCompressorData]);

  const isOnlyNumber = (value: string) => {
    const onlyNumberRegex = /^[0-9]+$/;
    return onlyNumberRegex.test(value);
  };

  const processTableTextValues = () => {
    const deviceNames: string[] = [];
    const deviceAddress: string[] = [];
    const deviceSuctionNames: string[] = [];
    deviceCompressorData?.suction?.forEach(suction => {
      deviceNames.push(suction.name);
      deviceAddress.push(suction.address);
      deviceCompressorData?.dualSuctionDevices?.forEach(childSuction => {
        if (checkforSameDevices(childSuction, suction)) {
          deviceSuctionNames.push(childSuction.name);
        }
      });
    });

    setDeviceAddress(deviceAddress);
    setDeviceSuctionNames(deviceSuctionNames);
    setDeviceNames(deviceNames);
    setIsTableTextValuesUpdated(true);
  };

  const handleOnSuctionGroupChange = (options: SelectInputOption) => {
    const selectedRowIndex: number = +options.value;
    const selectedSgValue: number = +options.label;
    const currentSelectedRowNumSg: number = getCurrentSuctionGroupCount(
      deviceCompressorData?.suction,
      selectedRowIndex,
    );
    const suctionGroupCount = getSuctionGroupCount(
      deviceCompressorData?.suction,
    );
    currentSgFocus.target.blur();

    if (
      suctionGroupCount + selectedSgValue - currentSelectedRowNumSg >
      +deviceConstraintsData.max_sg
    ) {
      Notification.error({
        message: t('t17'),
        description: t('t3676'),
        duration: 5,
        theme,
      });
    }

    if (selectedSgValue > currentSelectedRowNumSg) {
      handleOnSuctionChange(selectedRowIndex, selectedSgValue);
      return;
    }

    if (currentSelectedRowNumSg === 1 && selectedSgValue === 0) {
      Notification.error({
        message: t('t17'),
        description: t('t3363'),
        duration: 3,
        theme,
      });
      return;
    }

    if (currentSelectedRowNumSg > 1 && selectedSgValue === 0) {
      setWarningMessage(t('t3362'));
      setSelectedRowIndex(selectedRowIndex);
      setSelectedSgCount(1);
      handleShowWarningModal();
      return;
    }

    if (
      currentSelectedRowNumSg > 1 &&
      selectedSgValue < currentSelectedRowNumSg
    ) {
      setWarningMessage(t('t3362'));
      setSelectedRowIndex(selectedRowIndex);
      setSelectedSgCount(selectedSgValue);
      handleShowWarningModal();
    }
  };

  const handleOnDeleteSelectedRow = (selectedRowIndex: number) => {
    handleDeleteSelectedRow(selectedRowIndex);
  };

  const saveSuctionName = (
    event: React.FocusEvent<HTMLInputElement>,
    selectedRowIndex: number,
  ) => {
    const { value } = event.target;

    const selectedDeviceDualSuction: DiscoveryTableData =
      deviceCompressorData.dualSuctionDevices[selectedRowIndex];
    if (selectedDeviceDualSuction?.name !== value) {
      const selectedDiscoveryTableData: DiscoveryTableData =
        discoveryTableData?.find(
          device => device.deviceId === selectedDeviceDualSuction.deviceId,
        );
      selectedDiscoveryTableData.name = value.length
        ? value
        : selectedDiscoveryTableData.name;

      const updatedData: DeviceCompressorData = processCompressorData(
        discoveryTableData,
        t,
        deviceTypeModelList,
        false,
      );
      setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
      setDeviceCompressorData(updatedData);
    }
  };

  const saveName = (
    event: React.FocusEvent<HTMLInputElement>,
    selectedRowIndex: number,
  ) => {
    const { value } = event.target;

    const selectedDeviceSuction: DiscoveryTableData =
      deviceCompressorData.suction[selectedRowIndex];

    if (selectedDeviceSuction?.name !== value) {
      const selectedDiscoveryTableData: DiscoveryTableData =
        discoveryTableData?.find(
          device => device.deviceId === selectedDeviceSuction.deviceId,
        );

      selectedDiscoveryTableData.name = value;

      const updatedData: DeviceCompressorData = processCompressorData(
        discoveryTableData,
        t,
        deviceTypeModelList,
        false,
      );
      setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
      setDeviceCompressorData(updatedData);
    }
  };

  const validateAndSaveAddress = (value: string, selectedRowIndex: number) => {
    const selectedDeviceSuction: DiscoveryTableData =
      deviceCompressorData.suction[selectedRowIndex];

    if (selectedDeviceSuction?.address !== value) {
      let selectedDeviceDualSuction: DiscoveryTableData = {};
      deviceCompressorData?.dualSuctionDevices?.forEach(dualSuctionDevice => {
        if (checkforSameDevices(dualSuctionDevice, selectedDeviceSuction)) {
          selectedDeviceDualSuction = dualSuctionDevice;
        }
      });

      const selectedSuctionDiscoveryTableData: DiscoveryTableData[] =
        discoveryTableData?.filter(
          device =>
            device.deviceId === selectedDeviceSuction.deviceId ||
            device.deviceId === selectedDeviceDualSuction?.deviceId,
        );

      const invalidMessage: string = validateAddress(
        value,
        selectedDeviceSuction?.deviceId,
        deviceConstraintsData,
        discoveryTableData,
      );

      let address: string = '';
      if (invalidMessage) {
        Notification.error({
          message: t('t17'),
          description:
            invalidMessage === 't3520'
              ? t(invalidMessage, { maxAddr: deviceConstraintsData.max_naddr })
              : t(invalidMessage),
          duration: 3,
          theme,
        });
        address = '0';
      } else {
        address =
          value.trim() !== '' &&
          !hasSpecialCharacters(value) &&
          isOnlyNumber(value)
            ? value
            : '0';
      }
      selectedSuctionDiscoveryTableData.forEach(device => {
        device.address = address;
      });

      const updatedData: DeviceCompressorData = {
        updatedDiscoveryTableData: discoveryTableData,
        suction: deviceCompressorData.suction,
        dualSuctionDevices: deviceCompressorData.dualSuctionDevices,
      };
      setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
      setDeviceCompressorData(updatedData);
    }
  };

  const handleOnModelListNameChange = (
    option: SelectInputOption,
    selectedRowIndex: number,
    device_id: number,
  ) => {
    const selectedDevice = discoveryTableData.find(
      data => device_id === data.deviceId,
    );
    if (selectedDevice?.model === option.value) {
      return;
    }

    const updatedData = updateSelectedDeviceInfo(
      modelListNameOptions,
      option,
      selectedRowIndex,
      deviceCompressorData,
      discoveryTableData,
      deviceConstraintsData,
      deviceTypeModelList,
      t,
    );
    setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
    setDeviceCompressorData(updatedData);
  };

  const modelListNameOptions: SelectInputOption[] =
    deviceTypeModelList?.modelList?.map(value => ({
      value: value._,
      label: value._,
    }));

  const getCompressorTableData = () => {
    const treeViewData = [];
    deviceCompressorData?.suction.forEach((compressor, index) => {
      treeViewData.push({
        id: index,
        isTextInput: true,
        name: deviceNames[index],
        address: deviceAddress[index],
        model: compressor.listname,
        suctionGroups: compressor.num_sg?.toString(),
        compressor,
        items: getChilderSuction(compressor),
      });
    });
    setCompressorTableData(treeViewData);
    setIsTableTextValuesUpdated(false);
  };

  const getChilderSuction = (compressor: DiscoveryTableData) => {
    const treeItem = [];
    deviceCompressorData?.dualSuctionDevices?.forEach((childSuction, index) => {
      if (checkforSameDevices(childSuction, compressor)) {
        treeItem.push({
          id: index,
          name: deviceSuctionNames[index],
          isTextInput: true,
          deviceType: childSuction.deviceType,
        });
      }
    });
    return treeItem;
  };

  const getSuctionGroupOptions = (
    maxSg: number,
    index: number,
  ): SelectInputOption[] => {
    const suctionGroupValues: number[] = generateNumberArray(0, maxSg);

    return suctionGroupValues.map(suctionGroupValue => ({
      value: index.toString(),
      label: suctionGroupValue.toString(),
    }));
  };

  const columns: TreeViewTableColumn[] = [
    {
      key: 'col0',
      width: 1,
    },
    {
      key: 'col1',
      title: t('t76').toUpperCase(),
      width: 35,
    },
    {
      key: 'col2',
      title: t('t57').toUpperCase(),
      width: 10,
    },
    {
      key: 'col3',
      title: t('t355').toUpperCase(),
      width: 35,
    },
    {
      key: 'col4',
      title: t('t519').toUpperCase(),
      width: 14,
    },
    {
      key: 'col5',
      width: 4,
    },
  ];

  const handleOnAddPack = () => {
    if (deviceCompressorData?.suction?.length) {
      handleOnCopyOk(1, 0);
    } else {
      handleOnAddRack();
    }
  };

  const getRowTemplate = ({ item, options }) => {
    const { getToggleProps, openSections } = options;
    const { onClick, onKeyDown, ...toggleProps } = getToggleProps();
    const hasNestedItems = !!item.items?.length;
    const isOpen = openSections.includes(item.id);

    const renderChildren = item => {
      if (!hasNestedItems) return null;

      return item.items.map(subitem => (
        <Div
          testId={`suction-sub-row-${subitem.id}-div`}
          width="35%"
          m={`${theme.spacing.xxs}px ${theme.spacing.xxlg * 2}px`}
        >
          <TextInput
            type="text"
            testId="compressorFlow-saveSuction-input"
            defaultValue={subitem.name}
            key={subitem.name}
            onBlur={e => {
              saveSuctionName(e, subitem.id);
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            }}
            maxLength={+deviceConstraintsData?.max_name}
            disabled={getIsDualSuctionEditable(subitem?.deviceType)}
            height="auto"
            styles={{ root: { width: '85%' } }}
          />
        </Div>
      ));
    };

    return (
      <Div testId="parent-div" flex={1} mt="5px">
        <Div testId="toggle-div" display="flex">
          <TreeViewItemToggleDiv
            hasNestedItems={hasNestedItems}
            onClick={e => onClick(e, item)}
            testId={`${item.id}-tree-item`}
            {...toggleProps}
            style={{
              alignSelf: 'center',
            }}
          >
            <Icon
              glyph={icons.CHEVRON_DOWN}
              rotate={isOpen ? 0 : -90}
              size={16}
            />
          </TreeViewItemToggleDiv>
          <Div
            testId="content-div"
            display="flex"
            flex="1"
            ml="4px"
            style={{ gap: '8px' }}
          >
            <Div testId={`name-row-${item.id}-div`} width="35%">
              <TextInput
                type="text"
                testId="compressorFlow-saveName-input"
                defaultValue={item.name}
                key={item.name}
                onBlur={e => {
                  saveName(e, item.id);
                }}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    e.currentTarget.blur();
                  }
                }}
                maxLength={+deviceConstraintsData?.max_name}
                disabled={getIsNameEditable(item.compressor?.deviceType)}
                styles={{ root: { width: '85%' } }}
                height="auto"
              />
            </Div>
            <Div testId={`address-row-${item.id}-div`} width="10%">
              <NumberInput
                type="text"
                value={item.address}
                testId={`compressorAddress-${item.id ?? ''}-numberInput`}
                key={item.address}
                onBlur={e => {
                  validateAndSaveAddress(e.target.value, item.id);
                }}
                onKeyDown={event => {
                  // Prevent letters in the input field
                  if (event.keyCode > 64 && event.keyCode < 91) {
                    event.preventDefault();
                  }
                }}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    e.currentTarget.blur();
                  }
                }}
                maxLength={+deviceConstraintsData?.max_naddr.length}
                maxWidth={60}
                isDisabled={getIsAddressEditable(
                  item.compressor.deviceType,
                  item.compressor.online.toString() === '1',
                )}
                styles={{ root: { width: '65%' } }}
                height="auto"
              />
            </Div>
            <Div testId={`model-row-${item.id}-div`} width="35%">
              <SelectInput
                value={{
                  label: item.compressor.listname,
                  value: '',
                }}
                name=""
                size="small"
                options={modelListNameOptions}
                disabled={getIsModelEditable(
                  item.compressor.deviceType,
                  item.compressor.online === '1',
                )}
                onChange={(event: SelectInputOption) => {
                  handleOnModelListNameChange(
                    event,
                    item.id,
                    item.compressor.deviceId,
                  );
                }}
                searchable={true}
                styles={{
                  root: {
                    width: '85%',
                  },
                }}
              />
            </Div>
            <Div testId={`suction-group-row-${item.id}-div`} width="14%">
              <SelectInput
                value={{
                  label: item.compressor?.num_sg?.toString(),
                  value: '',
                }}
                name=""
                size="small"
                onChange={(option: SelectInputOption) => {
                  handleOnSuctionGroupChange(option);
                }}
                onFocus={e => {
                  e.persist();
                  currentSgFocus = e;
                }}
                options={getSuctionGroupOptions(
                  item.compressor?.max_sg,
                  item.id,
                )}
                disabled={getIsSuctionGroupEditable(
                  item.compressor.deviceType,
                  item.compressor.online === '1',
                )}
                styles={{
                  root: {
                    width: '55%',
                  },
                }}
              />
            </Div>
            <Div testId="copy-delete-div" width="5%">
              <LayoutDropDownMenu
                rowIndex={item.id}
                handleOnCopyOk={handleOnCopyOk}
                handleDeleteSelectedRow={handleOnDeleteSelectedRow}
              />
            </Div>
          </Div>
        </Div>
        <Div>{isOpen && renderChildren(item)}</Div>
      </Div>
    );
  };

  return (
    <>
      <Div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Icon
          glyph={icons.WARNING}
          styles={{
            root: {
              color: theme.palette.warning.main,
            },
          }}
          size={32}
        />
        <Div style={{ marginTop: '5px', marginLeft: '5px' }}>{t('t3676')}</Div>
      </Div>
      <ContainerDimensions>
        {() =>
          tableDataLoading ? (
            <Div
              testId="compressor-flow-table-spinner-div"
              style={{ marginTop: '200px' }}
            >
              <Spinner size={SpinnerSize.small} />
            </Div>
          ) : !deviceCompressorData?.suction?.length ? (
            <>
              <Div
                testId="compressor-flow-table-empty-div"
                style={{ marginTop: '200px' }}
              >
                <EmptyState title={t('t3365')} />
              </Div>
              <Div
                testId="add-pack-div"
                style={{
                  marginTop: '100px',
                  width: '100%',
                  display: 'inline-block',
                }}
              >
                <CompressorsFlowAddPack
                  handleOnAddPack={handleOnAddPack}
                  disabled={maxSgReached}
                />
              </Div>
            </>
          ) : (
            <Div
              testId="compressor-flow-table-content-div"
              style={{ marginTop: '20px' }}
            >
              <TreeViewTable
                testId="compressorFlow-addPack-treeviewtable"
                columns={columns}
                data={compressorTableData}
                rowTemplate={getRowTemplate}
              />
              <Div
                testId="add-pack-div"
                style={{
                  width: '100%',
                  display: 'inline-block',
                }}
              >
                <CompressorsFlowAddPack
                  handleOnAddPack={handleOnAddPack}
                  disabled={maxSgReached}
                />
              </Div>
            </Div>
          )
        }
      </ContainerDimensions>
    </>
  );
};
